import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Nathan Hundley, PhD | Storyteller, ML Specialist, Psychometrician, & IO Psychologist', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to my website', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hello, my name is',
  name: 'Nathan',
  subtitle: 'I am an IO Psychologist specializing in Assessment Development and End-to-End Machine Learning.',
  cta: 'Know more',
};

// ABOUT DATA
export const aboutData = {
  img: 'Nathan-Hundley-Square.jpg',
  paragraphOne: 'I am a storyteller who combines Industrial-Organizational Psychology and Machine Learning to understand people.',
  paragraphTwo: 'I am passionate about Model Development and Deployment, understanding all areas of text analytics from Generation to Understanding, and entwining Machine Learning/Deep Learning with Assessment.',
  paragraphThree: '   ',
  resume: 'https://drive.google.com/file/d/1gLsvb5KmEao03K0b_NOedBHSyHH39KwU/view', // if no resume, the button will not show up
  github: 'https://github.com/NathanHundley',
  linkedin: 'https://www.linkedin.com/in/nathanhundley',
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'hogan',
    title: 'Candidate Assessment Suite Algorithm Development',
    info: 'I used machine learning to develop 75 algorithmic alternatives to key products predicting employee performance.',
    info2: 'I led a team examining the criterion, construct, and content validity; reliability; and, adverse impact/group differences of the final algorithms. I modified models to reduce bias as needed based on domain expertise.',
    url: 'https://www.hoganassessments.com/products-talent-acquisition/candidate-selection/candidate-assessment-suite/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'hogan-nlp',
    title: 'Personality Relevance Classification Using NLP',
    info: 'I streamlined a focus group note coding process using natural language processing.',
    info2: 'I used a group of subject matter experts (SMEs) to establish a human benchmark for comparison. Final models identified personality relevance more accurately than SMEs, and reduced coding process time by a factor of 90.',
    url: 'https://www.hoganassessments.com/natural-language-processing-at-hogan/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'face_app',
    title: 'Full Stack Face Recognition Application',
    info: 'I developed a full-stack facial recognition application using the API from Clarifai. Users register for the application and are then able to upload images. The Clarifai API identifies the location of any faces in the image, and the application draws boundary boxes around them.',
    info2: 'The front-end is built using React.js and includes both sign in and registration pages. The back-end was built using Node.js and Express.js with a PostgreSQL relational database to keep track of user names, emails, and hashed versions of their passwords.',
    url: 'https://shrouded-ocean-41995.herokuapp.com/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'pwned',
    title: 'Secure Password Checker',
    info: 'I built a secure password checker in Python using hash functions.',
    info2: 'The script converts the password to be checked to SHA1 hash. The first five characters of the hash password are sent to the Pwned Passwords API. The API checks all hashed passwords with the same first five characters. API matches are sent back to the script which identifies whether the password was previously leaked by matching API results to the entire SHA1 hash stored locally. Pwned Passwords API never sees the password, completely secure.',
    url: 'https://github.com/NathanHundley/Password-Checker',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: '',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: '',
    },
    {
      id: nanoid(),
      name: 'codepen',
      url: '',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: '',
    },
    {
      id: nanoid(),
      name: 'github',
      url: '',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
